


































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const cookies = require('js-cookie')
const UIMutation = namespace('ui').Mutation
const AccountAction = namespace('account').Action

const AppProps = Vue.extend({
  props: ['password_enabled', 'twofa_enabled', 'magic_token'],
  data: () => {
    return {
      account_key: '',
      password: '',
      errorMessage: '',
      pw_required: false,
      twofa_required: false
    }
  },
  mounted () {
    if(!this.magic_token) {
      this.$router.push('home')
    }
    // router can only pass props as a string, convert to boolean for safety
    this.pw_required = JSON.parse(this.password_enabled.toLowerCase())
    this.twofa_required = JSON.parse(this.twofa_enabled.toLowerCase())
  },
  methods: {
    async loginMagic() {
      this.errorMessage = ''
      let payload = {
        password: this.password_input,
        totp: this.two_fa_input,
        accountKey: this.magic_token
      }
      let res = await this.retrieveJWTFromAccountKey(payload)
      if (res == undefined) {
        this.errorMessage = "Invalid credentials"
      } else {
        if(this.pw_required) {
            this.setSecuredByPassword(true)
        }
        cookies.set('isMagicTokenLogin', true);
        await this.getUserData()
        this.$router.push('home')
      }
    }
  },
  created () {
    // @ts-ignore
    this.setSimpleFooter(true)
    this.setFooterDisabled(false)
    this.setBalanceDisabled(false)
  }
})
@Component({
  components: {}
})
export default class Login extends AppProps {
  @UIMutation('setSimpleFooter') setSimpleFooter
  @UIMutation('setFooterDisabled') setFooterDisabled
  @UIMutation('setBalanceDisabled') setBalanceDisabled
  @AccountAction('retrieveJWTFromAccountKey') retrieveJWTFromAccountKey
  @AccountAction getUserData
  @AccountAction setSecuredByPassword
}
